import React, { useEffect, useState } from "react";
import { Margin, usePDF } from "react-to-pdf";
import Seo from "../assets/components/seo";
import {
  Button,
  Chip,
  Container,
  Dialog,
  Fab,
  Grid,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { AccessTimeOutlined } from "@mui/icons-material";

import { app } from "../utils/server/firebase";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";

import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";
import Heading from "../assets/components/Heading";
import moment from "moment";
import "moment/locale/es-mx";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "react-i18next";

const db = getFirestore(app);

const Programa = () => {
  const theme = useTheme();
  const [programa, setPrograma] = useState([]);
  const [categorySelected, setCategorySelected] = useState("15 de noviembre");
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const fechas = [
    "15 de noviembre",
    "16 de noviembre",
    "17 de noviembre",
    "18 de noviembre",
    "19 de noviembre",
  ];

  const formatDate = (fecha) => {
    const dia = moment(fecha).format("DD");

    const mes = moment(fecha).format("MMMM");

    return `${dia} de ${mes}`;
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "programa"), (querySnapshot) => {
      const result = [];
      querySnapshot.forEach((doc) => {
        const activity = doc.data();
        activity.id = doc.id;
        result.push({ ...activity });
      });
      setPrograma(result);
      console.log(result);
    });
    return unsub;
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const { toPDF, targetRef } = usePDF({
    filename: "programa.pdf",
    page: {
      margin: Margin.MEDIUM,
      format: "A4",
    },
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
      }}
    >
      <Seo
        title="Programa - Festival del Chocolate"
        description="Conoce el programa de actividades del 12vo Festival del Chocolate"
      />
      <Navbar />

      <Grid
        container
        justifyContent="center"
        paddingY={12}
        minHeight="60vh"
        spacing={4}
      >
        <Grid item container maxWidth={1400}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                color="white"
                position="absolute"
                textAlign="center"
              >
                {t("titulo")}
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          marginTop={2}
          spacing={2}
        >
          {fechas.map((tag, index) => (
            <Grid item key={index}>
              <Chip
                label={tag}
                size="medium"
                variant={categorySelected === tag ? "filled" : "outlined"}
                style={{
                  backgroundColor:
                    categorySelected === tag
                      ? theme.palette.primary.main
                      : undefined,
                  color: categorySelected === tag ? "white" : undefined,
                }}
                onClick={() => setCategorySelected(tag)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button variant="contained" onClick={toPDF}>
            Descarga el programa del día
          </Button>
        </Grid>
        <Grid
          item
          container
          maxWidth="md"
          ref={targetRef}
          marginTop={4}
          marginX={2}
        >
          {programa
            .filter((item) => {
              return (
                formatDate(item.inicio.toDate()) == categorySelected &&
                !item.disabled
              );
            })
            .sort((a, b) => {
              // Ordénalos de manera ascendente según la propiedad 'inicio'
              return a.inicio - b.inicio;
            })
            .map((item, key) => (
              <Grid
                item
                container
                bgcolor={
                  item.salon === "Chocolate" ||
                  item.salon === "Tabá Cao" ||
                  item.salon === "Cacao" ||
                  item.salon === "Cavacao"
                    ? theme.palette.primary.main
                    : item.salon === "Sabor a tabasco"
                    ? "#fcb200"
                    : item.salon === "Área Infantil"
                    ? "#dd682e"
                    : null
                }
                justifyContent="center"
                key={key}
                borderRadius={2}
                mb={1}
                p={1}
              >
                <Grid
                  item
                  container
                  xs={3}
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  p={1}
                >
                  <Grid item>
                    <AccessTimeOutlined htmlColor="white" fontSize="large" />
                  </Grid>
                  <Grid item>
                    <Typography
                      color="white"
                      textAlign="center"
                      variant="h6"
                      whiteSpace="pre-wrap"
                    >
                      {moment(item.inicio.toDate()).format("LT")} -{" "}
                      {moment(item.fin.toDate()).format("LT")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  direction="column"
                  xs={9}
                  p={1}
                >
                  <Grid item>
                    <Typography
                      color="white"
                      variant="h5"
                      whiteSpace="pre-wrap"
                    >
                      {item.nombre}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="white" variant="body1">
                      {item?.ponente}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      textAlign="end"
                      color="white"
                      variant="subtitle1"
                    >
                      {item.salon === "Chocolate"
                        ? "Salón Chocolate Nave Chocolate"
                        : item.salon === "Tabá Cao" || item.salon === "Cacao"
                        ? "Salón Cacao Nave Chocolate"
                        : item.salon === "Cavacao"
                        ? "Salón Cavacao Nave Chocolate"
                        : item.salon === "Sabor a tabasco"
                        ? "Sabor a Tabasco Área Gastronómica"
                        : item.salon === "Área Infantil"
                        ? "Área Infantil Nave Cacao"
                        : item.salon}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Typography variant="caption">{t("nota")}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Heading label={t("headingFestival")} />
          </Grid>
          {t("actividades", { returnObjects: true }).map((actividad) => (
            <Grid item container xs={6} lg={3} rowSpacing={1}>
              <Grid item xs={12} textAlign="center">
                <img
                  src={actividad.image}
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: 70,
                    objectFit: "contain",
                  }}
                  alt={actividad.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  color="#6c3f37"
                  fontWeight="bold"
                >
                  {actividad.name}
                </Typography>
              </Grid>
              <Grid item width="90%">
                <Typography>{actividad.content}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Footer />
      <Fab
        variant="extended"
        color="primary"
        onClick={() => navigate("/reservaciones")}
        style={{
          margin: 0,
          top: "auto",
          left: 20,
          bottom: 20,
          right: "auto",
          position: "fixed",
        }}
      >
        {t("catas")}
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        {/* <a
          href="https://festivaldelqueso.com.mx/"
          target="_blank"
          rel="noreferrer"
        > */}
        <img
          src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/11/05/20231105200644-806c0992-me.jpg"
          style={{
            width: "100%",
            maxWidth: 900,
            objectFit: "contain",
            cursor: "pointer",
          }}
        />
        {/*  </a> */}
      </Dialog>
    </Container>
  );
};

export default Programa;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["programa"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
